import React from 'react';

function add_tenant_row(params) {
  if (params.lease != null) {
    var nameSpan =
      "<span class='name' title='" +
      params.name +
      ' - ' +
      params.lease.building_name +
      ' ' +
      params.lease.unit_name +
      "'>\
        <div class='firstname_order'>" +
      params.name +
      "</div>\
        <div class='lastname_order' style='display: none'>" +
      params.lastnameOrder +
      "</div>\
        <div class='site_info' style='display: none'>" +
      params.lease.building_name +
      ' ' +
      params.lease.unit_name +
      "</div>\
        <div class='company_order' style='display: none'>" +
      params.lease.company_name +
      '</div>\
      </span>';
  } else {
    var nameSpan =
      "<span class='name' title='" +
      params.name +
      "'>\
        <div class='firstname_order'>" +
      params.name +
      "</div>\
        <div class='lastname_order' style='display: none'>" +
      params.lastnameOrder +
      '</div>\
      </span>';
  }

  return (
    "<div class='table_row tenants_table_row_entity_" +
    params.eid +
    "' id='" +
    params.tenantRowId +
    "' data-entity-id='" +
    params.eid +
    "' data-entity-name='" +
    params.name +
    "' data-specific-lease='" +
    params.leaseId +
    "'>\
    <input type='hidden' name='tenant_" +
    params.eid +
    "' value='" +
    params.eid +
    "'>\
    <div class='row_column column_1'>\
      <div class='" +
    params.unreadEmailsClass +
    "'></div>" +
    nameSpan +
    "<div class='outstanding_invoice_count' style='float:right;'>" +
    params.outstandingInvoiceCount +
    "</div>\
    </div>\
    <div class='column_separator'></div>\
    <div class='row_column column_2'>\
      <span title='" +
    params.outstanding +
    "'>" +
    params.outstanding +
    '</span>\
    </div>\
  </div>'
  );
}

function add_vendor_row(params) {
  var outstandingAmountSpan = '';
  if (params.billTransactionTabAccessLevel.toString() == 'true') {
    outstandingAmountSpan =
      "<span title='" +
      params.outstanding +
      "'>" +
      params.outstanding +
      '</span>';
  }

  return (
    "<div data-active='" +
    params.active +
    "' id='vendors_table_row_" +
    params.eid +
    "' class='table_row'>\
    <input type='hidden' value='" +
    params.eid +
    "' id='vendor_" +
    params.eid +
    "' name='vendor_" +
    params.eid +
    "'>\
    <div class='row_column column_1'>\
        <div class='" +
    params.unreadEmailsClass +
    "'></div>\
      <span title='" +
    params.name +
    "' class='name'>\
        <div class='firstname_order'>" +
    params.name +
    "</div>\
        <div style='display: none;' class='lastname_order'>" +
    params.lastname +
    "</div>\
      </span>\
        <div class='outstanding_invoice_count' style='float:right;'>" +
    params.billCountStr +
    "</div>\
    </div>\
    <div class='column_separator'></div>\
    <div class='row_column column_2'>" +
    outstandingAmountSpan +
    '</div></div>'
  );
}

export default class ScrollableList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scrollPosition: 0,
      listItems: [],
      filterParams: {},
    };
  }

  componentWillMount() {
    window.filter_list = (filterParams) => {
      this.setState({
        scrollPosition: this.state.scrollPosition,
        filterParams: filterParams,
        listItems: this.state.listItems,
      });
    };

    window.update_list_items = (listItems) => {
      this.setState({
        scrollPosition: this.state.scrollPosition,
        filterParams: this.state.filterParams,
        listItems: listItems,
      });
    };
  }

  componentDidMount() {
    var that = this;
    $(this.list)
      .parents(this.props.scrollPaneSelector)
      .scroll(function () {
        setTimeout(function () {
          window.requestAnimationFrame(function () {
            that.updateScrollPosition(that.state.filterParams);
          });
        }, 0);
      });
  }

  componentDidUpdate() {
    var that = this;
    setTimeout(function () {
      window.requestAnimationFrame(function () {
        reinit_scroll_panes($(that.props.scrollPaneSelector).parentNode);
      });
    }, 0);
    if (typeof window.updateDisplayName === 'function')
      window.updateDisplayName();
  }

  updateScrollPosition(filterParams) {
    var scrollTop = 0;
    var that = this;
    var jsp = $(this.props.scrollPaneSelector).data('jsp');
    if (jsp != null) scrollTop = jsp.getContentPositionY();

    const newScrollPosition = scrollTop / this.props.heightOfItem;
    const difference = Math.abs(this.state.scrollPosition - newScrollPosition);

    if (difference >= this.props.maxItemsToRender / 5) {
      this.setState({
        scrollPosition: newScrollPosition,
        filterParams: filterParams,
        listItems: this.state.listItems,
      });
    }
  }

  render() {
    const startPosition =
      this.state.scrollPosition - this.props.maxItemsToRender > 0
        ? this.state.scrollPosition - this.props.maxItemsToRender
        : 0;

    const endPosition =
      this.state.scrollPosition + this.props.maxItemsToRender >=
      this.state.listItems.length
        ? this.state.listItems.length
        : this.state.scrollPosition + this.props.maxItemsToRender;

    if ($.isEmptyObject(this.state.filterParams)) {
      var tmpListItems = this.state.listItems;
    } else {
      var that = this;
      var tmpListItems = this.state.listItems.filter(function (item) {
        var filterPassed = false;
        for (var key in that.state.filterParams) {
          var value = that.state.filterParams[key];

          if (key == 'name') {
            filterPassed =
              item[key].toLowerCase().indexOf(value.toLowerCase()) != -1 ||
              value == '' ||
              value.toLowerCase() == 'search';
          } else if (key == 'entity_id') {
            filterPassed = item['params']['eid'] == value;
          } else {
            filterPassed =
              item[key].toLowerCase().indexOf(value.toLowerCase()) != -1;
          }

          if (!filterPassed) {
            break;
          }
        }

        return filterPassed;
      });
    }

    return (
      <div
        className="react-scrollable-list"
        ref={(list) => {
          this.list = list;
        }}
      >
        <div
          key="list-spacer-top"
          style={{
            height: startPosition * this.props.heightOfItem,
          }}
        ></div>
        {tmpListItems.slice(startPosition, endPosition).map((item) => (
          <div
            className="react-scrollable-list-item"
            key={'list-item-' + item.id}
            dangerouslySetInnerHTML={{
              __html: window['add_' + this.props.listItemType + '_row'](
                item.params
              ),
            }}
          ></div>
        ))}
      </div>
    );
  }
}
